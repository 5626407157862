.loginButton {
    background: linear-gradient(93.18deg, #003399 -3.99%, #76CE5B 97.37%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px !important;
    height: 60px;
    width: 80% !important;
    margin-left: 10% !important;
}

.logologin {
    height: 300px;
    margin-top: -100px;
}

.loginInput {
    background-color: #fff;
    border-radius: 5px;
    height: 35px;
    border-bottom: none;
}

.loginInput>div>input {
    border-radius: 5px;
    background-color: #fff;
    height: 35px !important;
    padding-top: 5px !important;
}

.loginText {
    font-family: 'Open Sans';
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    margin-top: -30px;
    margin-bottom: -15px;
    letter-spacing: 0.08em;
    color: #DADADA;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}